/**
 * @type {{isTablet, isIPhone, isAndroid, isPc}}
 */
var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
        /(?:iPad|PlayBook)/.test(ua) ||
        (isAndroid && !/(?:Mobile)/.test(ua)) ||
        (isFireFox && /(?:Tablet)/.test(ua)),
    isIPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isIpad = /iPad/i.test(ua),
    isPc = !isIPhone && !isAndroid && !isSymbian && !isIpad,
    isIE = window.ActiveXObject || 'ActiveXObject' in window,
    isEdge = /(?:Edge|edge)/.test(ua),
    isSafari = /Safari/.test(ua) && !/Chrome/.test(ua),
    isWin =
        navigator.platform === 'Win32' || navigator.platform === 'Windows',
    isWeChart = /(?:MicroMessenger)/.test(ua),
    isMac = /(?:OS X)/.test(ua);

var os = {
    isIpad: isIpad,
    isTablet: isTablet,
    isIPhone: isIPhone,
    isAndroid: isAndroid,
    isPc: isPc,
    isFireFox: isFireFox,
    isChrome: isChrome,
    isEdge: isEdge,
    isIE: isIE,
    isSafari: isSafari,
    isWin: isWin,
    isWeChart: isWeChart,
    isMac: isMac
};


module.exports = { os };
