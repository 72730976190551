import { debounce } from "lodash";

const listenResize = function(callback) {
  window.onresize = debounce(function () {
    callback({
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
    });
  }, 100)
  // 默认初始化
  callback({
    screenWidth: window.innerWidth,
    screenHeight: window.innerHeight,
  });
}

const listenScroll = function(callback) {
  window.addEventListener("scroll", function() {
    callback( document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop );
  });
  // 默认初始化
  callback( document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop );
}


export {
  listenResize,
  listenScroll
}