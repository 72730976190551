// 封装ajax请求
import axios from 'axios'
let httpCodes = [400, 401, 409, 422];

export default function ajax (url, data = {}, type = 'GET') {
  return new Promise(function (resolve) {
    let promise
    if (type === 'GET') {
        let dataStr = ''
        Object.keys(data).forEach(key => {
            dataStr += key + '=' + data[key] + '&'
        })
        if (dataStr !== '') {
            dataStr = dataStr.substring(0, dataStr.lastIndexOf('&'))
            url = url + '?' + dataStr
        }
        promise = axios.get(url)
    } else {
        promise = axios.post(url, data)
    }
    promise.then(response => {
        let data = {
            status: 200,
            data: response.data
        }
        resolve(data)
    }).catch(error => {
        let data = {}
        if(httpCodes.includes(error.response.status)) {
            let firstKey = Object.keys(error.response.data.info)[0]
            data = {
                status: error.response.status,
                data: error.response.data.info[firstKey]
            }
        }else {
            data = {
                status: error.response.status,
                data: `${error.response.status} ${error.response.statusText}`
            }
        }
        resolve(data)
    })
  })
}