import _ajax from './ajax';
let url_oss = 'https://website.xdcdn.net/homepage/torchlight'
let url_form = 'https://website.xdcdn.net/form/website/torchlight';
let api_url = 'https://poster-api.xd.com/api/v1.1/google'
let api_count = 'https://poster-api.xd.com'

const getUpdateMsg = () => _ajax(url_oss + '/update.json');
const getUpdateMsgEn = () => _ajax(url_oss + '/updateEn.json');
const googleLogin = data => _ajax(api_url + '/token-info', data);
const getRegisterInfo = data => _ajax(api_url + '/pre-register/info', data);
const reqRegister = data => _ajax(api_url + '/pre-register', data, 'POST');
const getIpCountry = () => _ajax('/myloc2');
const getCount = data => _ajax(api_count + '/api/v1.1/google/sync/count', data);
const getNews = data => _ajax(url_form + '/news.json', data);
const getNewSingle = data => _ajax(url_form + `/news/${data.lang}/${data.id}.json`, { t: data.t });
const getNewsCn = data => _ajax(url_form + '/news_cn.json', data);
const getNewSingleCn = data => _ajax(url_form + `/news_cn/${data.id}.json`, { t: data.t });
const getDomesticReserveCount = data => _ajax(url_form + '/getReseservation.json', data);


export {
    getUpdateMsg,
    getUpdateMsgEn,
    googleLogin,
    getRegisterInfo,
    reqRegister,
    getIpCountry,
    getCount,
    getNews,
    getNewSingle,
    getNewsCn,
    getNewSingleCn,
    getDomesticReserveCount
}