var xd_footer_content_v6 = (function(e) {
  var t = {};
  function n(o) {
      if (t[o]) return t[o].exports;
      var r = (t[o] = { i: o, l: !1, exports: {} });
      return e[o].call(r.exports, r, r.exports, n), (r.l = !0), r.exports;
  }
  return (
      (n.m = e),
      (n.c = t),
      (n.d = function(e, t, o) {
          n.o(e, t) ||
              Object.defineProperty(e, t, { enumerable: !0, get: o });
      }),
      (n.r = function(e) {
          'undefined' != typeof Symbol &&
              Symbol.toStringTag &&
              Object.defineProperty(e, Symbol.toStringTag, {
                  value: 'Module',
              }),
              Object.defineProperty(e, '__esModule', { value: !0 });
      }),
      (n.t = function(e, t) {
          if ((1 & t && (e = n(e)), 8 & t)) return e;
          if (4 & t && 'object' == typeof e && e && e.__esModule) return e;
          var o = Object.create(null);
          if (
              (n.r(o),
              Object.defineProperty(o, 'default', {
                  enumerable: !0,
                  value: e,
              }),
              2 & t && 'string' != typeof e)
          )
              for (var r in e)
                  n.d(
                      o,
                      r,
                      function(t) {
                          return e[t];
                      }.bind(null, r)
                  );
          return o;
      }),
      (n.n = function(e) {
          var t =
              e && e.__esModule
                  ? function() {
                        return e.default;
                    }
                  : function() {
                        return e;
                    };
          return n.d(t, 'a', t), t;
      }),
      (n.o = function(e, t) {
          return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (n.p = '/element/footer_content_v6'),
      n((n.s = 0))
  );
})([
  function(e, t, n) {
      n(1), (e.exports = n(2));
  },
  function(e, t) {
      function n() {
          var e,
              t = '398px',
              n = '#232525',
              o = { lang: 'zh', app: '' },
              r =
                  (/^(local|127\.|172\.|192\.|10\.)/.test(
                      window.location.host
                  )
                      ? 'local.'
                      : '') + 'www.xd.com',
              a = window.location.protocol + '//' + r,
              u = document.getElementsByClassName('xd-common-footer-slot');
          if (
              (u.length
                  ? (e = u[0])
                  : ((e = document.createElement('div')).classList.add(
                        'xd-common-footer-slot'
                    ),
                    document.body.appendChild(e)),
              (e.style['min-height'] = t),
              (e.style['background-color'] = n),
              (o.lang = e.dataset.lang),
              (o.app = e.dataset.app),
              (o.userAgreement = e.dataset.userAgreement),
              window.URLSearchParams)
          ) {
              var s = new URLSearchParams(window.location.search);
              (o.userAgreement =
                  o.userAgreement || s.get('userAgreement') || ''),
                  (o.lang = o.lang || s.get('lang') || 'zh'),
                  (o.app = o.app || s.get('app') || '');
          }
          !(function(e) {
              var t = {
                  type: 'get',
                  url: '#',
                  data: {},
                  dataType: 'text',
                  async: !0,
                  success: function(e) {},
              };
              for (var n in e) t[n] = e[n];
              var o = null;
              if (window.XMLHttpRequest) o = new XMLHttpRequest();
              else o = new ActiveXObject('microsoft.XMLHTTP');
              var r = '';
              for (var a in t.data) r += a + '=' + t.data[a] + '&';
              r && (r = r.substring(0, r.length - 1)),
                  'get' === t.type && (t.url += '?' + encodeURI(r)),
                  o.open(t.type, t.url, t.async);
              var u = null;
              if (
                  ('post' == t.type &&
                      ((u = r),
                      o.setRequestHeader(
                          'Content-Type',
                          'application/x-www-form-urlencoded'
                      )),
                  o.send(u),
                  !t.async)
              )
                  return 'json' == t.dataType
                      ? JSON.parse(o.responseText)
                      : o.responseText;
              o.onreadystatechange = function(e) {
                  if (4 == o.readyState && 200 == o.status) {
                      e = o.responseText;
                      'json' == t.dataType && (e = JSON.parse(e)),
                          t.success(e);
                  }
              };
          })({
              url: a + '/games/xd_common_footer',
              data: o,
              dataType: 'html',
              success: function(t) {
                  (e.outerHTML = t),
                      window.jQuery &&
                          (window.jQuery('body').trigger('load.xd.footer'),
                          window.jQuery(window).trigger('resize'));
              },
          });
      }
      document.addEventListener('DOMContentLoaded', function() {
          n();
      });
  },
  function(e, t, n) {},
]);