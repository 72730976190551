import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getLocale } from "@/utils/getLocale";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: getLocale(),
  messages: {
    // zh	中文
    'zh-cn': require('./assets/locales/zh-CN.json'),
    'zh-tw': require('./assets/locales/zh-TW.json'),
    'zh-hk': require('./assets/locales/zh-TW.json'), // 兼容香港地区
    //英文
    'en': require('./assets/locales/en.json'),
    'us': require('./assets/locales/en.json'),
    // vn	越南语
    'vn': require('./assets/locales/vi.json'),
    // th	泰语
    'th': require('./assets/locales/th.json'),
    // id	印度尼西亚语
    'id': require('./assets/locales/id.json'),
    // ko	朝鲜语
    'kr': require('./assets/locales/ko.json'),
    // ja	日语
    'jp': require('./assets/locales/ja.json'),
    // de	德语
    'de': require('./assets/locales/de.json'),
    // fr	法语
    'fr': require('./assets/locales/fr.json'),
    // pt	葡萄牙语
    'pt': require('./assets/locales/pt.json'),
    // es	西班牙语
    'es': require('./assets/locales/es.json'),
    // ru	俄语
    'ru': require('./assets/locales/ru.json'),
  }
});

// function loadLanguageAsync(lang) {
//   return Promise.all([
//     import(`./assets/locales/${lang}.json`)
//       .then((messages) => {
//         console.log(messages)
//         i18n.setLocaleMessage(lang, messages.default);
//       })
//       .catch((error) => {
//         console.log(error);
//       })
//   ])
// }
// // 如果不希望使用多语言，加载所需语言即可
// loadLanguageAsync(locale);

export default i18n;
