import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes_overseas = [
  {
    path: '/',
    name: 'en',
    component: (resolve) => void require(['@/views/overseas/index'], resolve)
  },
  {
    path: '/index',
    name: 'en',
    component: (resolve) => void require(['@/views/overseas/index'], resolve)
  },
  {
    path: '/webpay',
    name: 'webpay',
    component: (resolve) => void require(['@/views/overseas/webpay'], resolve)
  },
  { path: '/zh-cn', name: 'zh-cn', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/zh-tw', name: 'zh-tw', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/zh-hk', name: 'zh-hk', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/en', name: 'en', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/us', name: 'us', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/vn', name: 'vn', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/th', name: 'th', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/id', name: 'id', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/kr', name: 'kr', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/jp', name: 'jp', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/de', name: 'de', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/fr', name: 'fr', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/pt', name: 'pt', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/es', name: 'es', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/ru', name: 'ru', component: (resolve) => void require(['@/views/overseas/index'], resolve) },
  { path: '/news', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/zh-cn/news', name: 'zh-cn', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/zh-tw/news', name: 'zh-tw', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/zh-hk/news', name: 'zh-hk', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/en/news', name: 'en', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/us/news', name: 'us', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/vn/news', name: 'vn', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/th/news', name: 'th', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/id/news', name: 'id', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/kr/news', name: 'kr', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/jp/news', name: 'jp', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/de/news', name: 'de', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/fr/news', name: 'fr', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/pt/news', name: 'pt', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/es/news', name: 'es', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/ru/news', name: 'ru', component: (resolve) => void require(['@/views/overseas/news'], resolve) },
  { path: '/news/single', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/zh-cn/news/single', name: 'zh-cn', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/zh-tw/news/single', name: 'zh-tw', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/zh-hk/news/single', name: 'zh-hk', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/en/news/single', name: 'en', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/us/news/single', name: 'us', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/vn/news/single', name: 'vn', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/th/news/single', name: 'th', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/id/news/single', name: 'id', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/kr/news/single', name: 'kr', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/jp/news/single', name: 'jp', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/de/news/single', name: 'de', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/fr/news/single', name: 'fr', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/pt/news/single', name: 'pt', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/es/news/single', name: 'es', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/ru/news/single', name: 'ru', component: (resolve) => void require(['@/views/overseas/single'], resolve) },
  { path: '/news/list', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/zh-cn/news/list', name: 'zh-cn', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/zh-tw/news/list', name: 'zh-tw', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/zh-hk/news/list', name: 'zh-hk', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/en/news/list', name: 'en', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/us/news/list', name: 'us', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/vn/news/list', name: 'vn', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/th/news/list', name: 'th', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/id/news/list', name: 'id', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/kr/news/list', name: 'kr', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/jp/news/list', name: 'jp', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/de/news/list', name: 'de', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/fr/news/list', name: 'fr', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/pt/news/list', name: 'pt', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/es/news/list', name: 'es', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
  { path: '/ru/news/list', name: 'ru', component: (resolve) => void require(['@/views/overseas/news-list'], resolve) },
];

const routes_domestic = [
  {
    path: '/',
    name: 'zh-cn',
    component: (resolve) => void require(['@/views/domestic/index'], resolve)
  },
  {
    path: '/index',
    name: 'zh-cn',
    component: (resolve) => void require(['@/views/domestic/index'], resolve)
  },
  { path: '/news/single', component: (resolve) => void require(['@/views/domestic/single'], resolve) },
  // { path: '/zh-cn/news/single', name: 'zh-cn', component: (resolve) => void require(['@/views/domestic/single'], resolve) },
  { path: '/news/list', component: (resolve) => void require(['@/views/domestic/news-list'], resolve) },
  // { path: '/zh-cn/news/list', name: 'zh-cn', component: (resolve) => void require(['@/views/domestic/news-list'], resolve) }
];

let routes = [];
if(process.env.VUE_APP_CONF_AREA == 'domestic') {
  routes = routes_domestic;
  require('@/assets/libs/footer_content_v6.js')
}else {
  routes = routes_overseas;
}

const router = new VueRouter({
  mode: 'history',
  routes: routes
});

export default router;

