<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { listenResize, listenScroll } from "@/utils/windowListener";
export default {
  name: 'App',
  created() {
    this.initGa();
  },
  mounted() {
    document.dispatchEvent(new Event('render-event'));
    
    listenResize((size) => {
      this.setInfo({
        key: "data_size_listen",
        val: size,
      });
      this.setDevice(size.screenWidth <= 750);
    });
    listenScroll((scrollTop) => {
      this.setInfo({
        key: "data_scrollTop_listen",
        val: scrollTop,
      });
    });
  },
  methods: {
    ...mapMutations(["setInfo", "setDevice"]),
    initGa() {
      var _gaq = _gaq || [];
      // _gaq.push(['_setAccount', 'UA-16408836-1']);
      // _gaq.push(['_setDomainName', 'xd.com']);
      // _gaq.push(['_trackPageview']);

      // (function() {
      //   var ga = document.createElement('script'); ga.type = 'text/javascript'; ga.async = true;
      //   ga.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'stats.g.doubleclick.net/dc.js';
      //   var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ga, s);
      // })();
      (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
          (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date(); a = s.createElement(o),
          m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
      })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

      ga('create', 'UA-16408836-1', 'auto');
      ga('send', 'pageview');
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/common/base.scss';
#app {
  min-height: 100vh;
}
</style>
