import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import 'swiper/css/swiper.css'
import i18n from './i18n';
import VueMeta from 'vue-meta'

import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  // preLoad: 1.3,
  attempt: 2,
  // listenEvents: [ 'scroll' ]
})

// 自定义插件
import vueInit from '@/plugins/vueInit';
Vue.use(vueInit)

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import VueLocalStorage from 'vue-localstorage'
Vue.use(VueLocalStorage)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
