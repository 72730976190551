import Vue from 'vue'
import Vuex from 'vuex'

import { os } from '@/assets/libs/os.js'
import * as api from '@/api/index'
import jsonEn from '@/assets/json/updateEn.json';

// import headerMap from '@/assets/libs/header'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
		// ----- 状态相关 -----
		loading: {
			finished: false,
			progress: 0,
		},
		navs: [
			{ id: "home", name: "HOME", fromTop: 0 },
			{ id: "news", name: "NEWS", fromTop: 0, intl: true },
			{ id: "features", name: "FEATURES", fromTop: 0 },
			{ id: "roles", name: "CHARACTERS", fromTop: 0 },
			{ id: "wiki", name: 'WIKI', fromTop: 0, link: 'https://torchlight.xd.com/en/wiki/gear/', intl: true },
			{ id: "register", fromTop: 0, intl: true }, // 仅用到fromTop跳转，不显示在导航栏
			{ id: "store", name: 'STORE', fromTop: 0, link: '/webpay', intl: true },
			{ id: "season", name: 'SEASON', fromTop: 0, link: '/ep1', intl: true },
		],
		navs_news: [
			{ id: "news", name: "NEWS", fromTop: 0, link: '/news/list' },
			{ id: "home", name: "HOME", fromTop: 0, link: '/' },
			{ id: "wiki", name: 'WIKI', fromTop: 0, link: '/en/wiki/gear/' },
			{ id: "store", name: 'STORE', fromTop: 0, link: '/webpay' },
			{ id: "season", name: 'SEASON', fromTop: 0, link: '/ep1' },
		],
		platforms: {
			register: {
				store: 'https://apps.apple.com/app/torchlight-infinite/id1593130084',
				google: 'https://play.google.com/store/apps/details?id=com.xd.TLglobal',
				taptap: 'https://www.taptap.io/app/227017',
				// steam: '',
				// pc: ''
			},
			download: {
				store: 'https://apps.apple.com/app/torchlight-infinite/id1593130084',
				google: 'https://play.google.com/store/apps/details?id=com.xd.TLglobal',
				taptap: 'https://www.taptap.io/app/227017',
				pc: 'https://torchlight-northamerica.torchlight.xd.com/ReleaseGlobal/Windows/TorchlightSetup_PC.4.0.0.3.exe'
			}
		},
		platforms_domestic: {
			register: {
				store: {
					link: 'https://apps.apple.com/cn/app/%E7%81%AB%E7%82%AC%E4%B9%8B%E5%85%89-%E6%97%A0%E9%99%90/id1528917194',
					icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/pre-register/CN-A.png'
				},
				taptap: {
					link: 'https://l.tapdb.net/q3ze8FEZ',
					icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/pre-register/CN-TAP.png'
				},
				pc: {
					link: 'https://l.tapdb.net/q3ze8FEZ',
					icon: '	https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/windows.png'
				}
			},
			download: {
				store: {
					link: 'https://apps.apple.com/cn/app/%E7%81%AB%E7%82%AC%E4%B9%8B%E5%85%89-%E6%97%A0%E9%99%90/id1528917194',
					icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/pre-register/CN-A.png'
				},
				taptap: {
					link: 'https://l.tapdb.net/q3ze8FEZ',
					icon: 'https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/download/Domestic-TAP.png'
				},
				pc: {
					link: '',
					icon: '	https://website.xdcdn.net/homepage/torchlight/imgs/overseas/obt2022/platform/windows.png'
				}
			}
		},
		modal_progress_show: false,
		stage: "download", // register download
		stage_domestic: "register",
		modal_video_options: {},
		modal_video_show: false,
		modal_video_player: null,
		modal_video_tip: "",
		seasonMap: {
			"en": "/en/ep",
			"jp": "/jp/ep",
			"kr": "/kr/ep",
			"zh-cn": "/zh-cn/ep",
			"zh-tw": "/tw/ep"
		},
		isMobile: false,
		modal_limit_show: false,


		// ----- 接口数据 -----
		data_detail: jsonEn,
		data_register_count: 0,

		// ----- 监听数据 -----
		data_size_listen: {},
		data_scrollTop_listen: 0,


		// ----- 原来的 -----
    showRegister: false,
    loginInfo: null,
    registerInfo: null,
    inTargetCountry: '',

    guideShow: '', // 下载引导遮罩
		downloadShow: '', // 显示下载按钮
		targetCountry: [
			'US', // 美国
			'CA', // 加拿大
			'AU', // 澳大利亚
			'NZ', // 新西兰
		],
		unTargetCountry: [
			'CN'
		],
		// targetCountry: [
		// 	'United States', // 美国
		// 	'Canada', // 加拿大
		// 	'Australia', // 澳大利亚
		// 	'New Zealand', // 新西兰
		// ],
		step: '', // 版本控制 1: 预约无下载 2: 目标地区，安卓IOS全下载 3: 目标地区，IOS关闭下载
		download: { // 下载链接
			taptap: '',
			Testflight: ''
		},
		os
  },
	getters: {
		scale(state) {
			const _scale = state.data_size_listen.screenWidth / 1920;
			return _scale < 1 ? _scale : 1;
		},
		platformList(state) {
			if(state.stage == 'register') {
				let list = {}
				if(os.isIPhone) {
					list = {
						// store: state.platforms.register.store
					}
				}else if(os.isAndroid) {
					list = {
						// google: state.platforms.register.google,
						// taptap: state.platforms.register.taptap
					}
				}else {
					list = {
						store: state.platforms.register.store,
						google: state.platforms.register.google,
						taptap: state.platforms.register.taptap
					}
				}
				return list
			}else if(state.stage == 'download') {
				let list = {}
				if(os.isIPhone) {
					list = {
						// store: state.platforms.download.store
					}
				}else if(os.isAndroid) {
					list = {
						google: state.platforms.download.google,
						taptap: state.platforms.download.taptap
					}
				}else {
					list = {
						store: state.platforms.download.store,
						google: state.platforms.download.google,
						taptap: state.platforms.download.taptap,
						pc: state.platforms.download.pc
					}
				}
				return list
			}
		},
		platformList_domestic(state) {
			if(state.stage_domestic == 'register') {
				let list = {}
				if(os.isIPhone) {
					list = {}
				}else if(os.isAndroid) {
					list = {}
				}else {
					list = {
						store: state.platforms_domestic.register.store,
						taptap: state.platforms_domestic.register.taptap,
						pc: state.platforms_domestic.register.pc
					}
				}
				return list
			}else if(state.stage_domestic == 'download') {
				let list = {}
				if(os.isIPhone) {
					list = {}
				}else if(os.isAndroid) {
					list = {}
				}else {
					list = {
						store: state.platforms_domestic.download.store,
						taptap: state.platforms_domestic.download.taptap,
						pc: state.platforms_domestic.download.pc
					}
				}
				return list
			}
		},
		mainBtn(state) {
			if(state.stage == 'register') {
				let mainBtn = {}
				if(os.isIPhone || os.isIpad) {
					mainBtn = {
						type: 'register',
						link: state.platforms.register.store,
						show: true,
						store: 'AppStore'
					}
				}else if(os.isAndroid) {
					mainBtn = {
						type: 'register',
						link: state.platforms.register.google,
						show: true,
						store: 'GooglePlay'
					}
				}else {
					mainBtn = {
						type: 'register',
						link: '',
						show: true,
						store: 'pc'
					}
				}
				return mainBtn
			}else if(state.stage == 'download') {
				let mainBtn = {}
				if(os.isIPhone || os.isMac || os.isIpad) {
					mainBtn = {
						type: 'download',
						link: state.platforms.download.store,
						show: true,
						store: 'AppStore'
					}
				}else if(os.isAndroid) {
					mainBtn = {
						type: 'download',
						link: state.platforms.download.google,
						show: true,
						store: 'GooglePlay'
					}
				}else {
					mainBtn = {
						type: 'download',
						link: 'https://torchlight-northamerica.torchlight.xd.com/ReleaseGlobal/Windows/TorchlightSetup_PC.4.0.0.3.exe',
						show: true,
						store: 'pc_install_package'
					}
				}
				return mainBtn
			}
		}
	},
  mutations: {
		toggleModal(state, payload) {
			state[payload.key] = payload.bool
		},
		setInfo(state, payload) {
			state[payload.key] = payload.val
		},
		setDistanceFromTop(state, payload) {
			const thisNav = state.navs.find(nav => nav.id === payload.key);
			if(thisNav) {
				thisNav.fromTop =  payload.val
			} else {
				throw new Error(`[state] ${payload.key} is not pre-set in state.navs!`)
			}
		},

		// ----- 原来的 -----
    setRegisterShow (state, bool) {
      state.showRegister = bool
    },
    setLoginInfo (state, info) {
      state.loginInfo = info
    },
    setRegisterInfo (state, info) {
      state.registerInfo = info
    },
    setInTargetCountry (state, bool) {
      state.inTargetCountry = bool
    },

		setGuideShow (state, bool) {
      state.guideShow = bool
		},
    setDownloadShow (state, bool) {
      state.downloadShow = bool
    },
		setStep (state, step) {
			state.step = step
		},
		setDownloadLink (state, obj) {
			state.download.taptap = obj.taptap || ''
			state.download.Testflight = obj.Testflight || ''
		},
		setNavs(state, payload) {
			state.navs = payload;
		},
		setDevice(state, payload) {
			state.isMobile = payload;
		},
  },
	actions: {
		updateNav({ commit, state }, { id, data }) {
			const navs = state.navs.map((navItem) => {
				if (navItem.id === id) {
					return {
						...navItem,
						...data,
					};
				}
				return {
					...navItem
				};
			});
			commit('setNavs', navs);
		},
		async getCount({ commit, state }, data) {
			const res = await api.getCount(data)
			if(res.status == 200) {
				commit('setInfo', {
					key: 'data_register_count',
					val: res.data.count  || 0
				})
			}
		},
		// async initInfo() {
    //   let info = await this.getMsg();
    //   if (info && Object.keys(info).length != 0) {
    //     this.news = info.news;
    //     this.more_news = info.more_news;
    //     this.videoID = info.videoID;
    //     this.features = info.features;
    //     // this.bdIntroduce = info.bdIntroduce;
    //     this.medias = info.mediaList;

    //     this.heroes.forEach((item) => {
    //       let heroName = item.name;
    //       item.skill = info.skills[heroName];
    //     });

    //     this.$nextTick(() => {
    //       this.initVideo();
    //     });
    //   }
    // },
		async getMsgEn({ commit, dispatch }) {
			const res = await api.getUpdateMsgEn();
			if(res.status == 200) {
				commit('setInfo', {
					key: 'data_detail',
					val: res.data
				})

				const info = res.data;
				commit('setStep', info.step)
				// commit('setStep', '3')
				commit('setDownloadLink', info.download)
				dispatch('stepControl')
				return info
			}else {
				return {}
			}
		},
    getSubCountry({ commit, dispatch, state }) {
			let subCountry = headerMap['xd-region']
			if(subCountry) {
				let inTargetCountry = state.unTargetCountry.includes(subCountry)
				commit('setInTargetCountry', !inTargetCountry)
				dispatch('stepControl')
			}else {
				commit('setInTargetCountry', true)
				dispatch('stepControl')
			}
    },
		stepControl({ commit, state }) {
			if(typeof state.inTargetCountry === 'boolean' && state.step != '') {
				let bool = '';
				switch (state.step) {
					case '1':
						// 显示预约, 无下载入口
						bool = false
						break;
					case '2':
						// 目标地区，安卓+IOS 均开放下载
						bool = state.inTargetCountry;
						break;
					case '3':
						// 目标地区, 安卓开放下载，IOS显示预约
						if(state.inTargetCountry && !os.isIPhone) {
							bool = true
						}else {
							bool = false
						}
						break;
				}
				commit('setDownloadShow', bool)
				commit('setGuideShow', bool)
			}
    },
		createModalVideo({ commit, state }, options) {
			commit('setInfo', {
				key: "modal_video_options",
				val: options
			});
			commit('toggleModal', {
        key: "modal_video_show",
        bool: true,
      })
			commit('setInfo', {
				key: "modal_video_tip",
				val: options.tip || ''
			})
		}
	}
})

export default store;